import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timezoneConverter',
})
export class TimezoneConverterPipe implements PipeTransform {
  transform(value: string, format: 'toISO' | 'fromISO'): string {
    if (format === 'toISO') {
      return this.convertCurrentTimezoneToISO(value);
    } else if (format === 'fromISO') {
      return this.convertISOToCurrentTimezone(value);
    } else {
      throw new Error('Invalid format. Expected "toISO" or "fromISO".');
    }
  }

  private convertCurrentTimezoneToISO(time: string): string {
    const timeFormat = /^\d{2}:\d{2}$/;
    if (!timeFormat.test(time)) {
      throw new Error('Invalid time format. Expected "HH:MM".');
    }
    const [hours, minutes] = time.split(':');
    const dateTime = new Date();
    dateTime.setHours(+hours, +minutes, 0, 0);
    return dateTime.toISOString().substring(11, 16);
  }

  private convertISOToCurrentTimezone(iso: string): string {
    if (!iso || iso === '') return '-';
    const isoFormat = /^\d{2}:\d{2}$/;
    if (!isoFormat.test(iso)) {
      throw new Error('Invalid ISO format. Expected "HH:MM".');
    }
    const [hours, minutes] = iso.split(':');
    const dateTime = new Date(Date.UTC(1970, 0, 1, +hours, +minutes));
    const hrs = dateTime.getHours().toString().padStart(2, '0');
    const mins = dateTime.getMinutes().toString().padStart(2, '0');
    return `${hrs}:${mins}`;
  }
}
