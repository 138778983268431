import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimezoneConverterPipe } from './timezone-converter.pipe';

@NgModule({
  declarations: [TimezoneConverterPipe],
  imports: [CommonModule],
  exports: [TimezoneConverterPipe],
  providers: [TimezoneConverterPipe],
})
export class TimezoneConverterPipeModule {}
